import Cookies from "js-cookie";

const cookieScope = process.env.REACT_APP_ENV as string;

const resolveKey = (key: string) => `${key}-${cookieScope}`;

export const cookieStorage = {
  getItem: (key: string): Promise<string | null> => {
    const value = Cookies.get(resolveKey(key));
    return Promise.resolve(value || null);
  },

  getItemSync: (key: string): string | null => {
    return Cookies.get(resolveKey(key)) || null;
  },

  setItem: (key: string, value: string): Promise<void> => {
    Cookies.set(resolveKey(key), value, {
      expires: 365,
      sameSite: "None",
      secure: true,
    });
    return Promise.resolve();
  },

  setItemSync: (key: string, value: string): void => {
    Cookies.set(resolveKey(key), value, {
      expires: 365,
      sameSite: "None",
      secure: true,
    });
  },

  removeItem: (key: string): Promise<void> => {
    Cookies.remove(resolveKey(key), { path: "/" });
    return Promise.resolve();
  },

  removeItemSync: (key: string): void => {
    Cookies.remove(resolveKey(key), { path: "/" });
  },
};

import {
  Box,
  Flex,
  Button,
  Collapse,
  Input,
  Select,
  Textarea,
  Tooltip,
  FormHelperText,
  FormControl,
} from "@chakra-ui/react";
import {
  MdCancel,
  MdAddCircle,
  MdExpandLess,
  MdExpandMore,
} from "react-icons/md";
import { useEffect } from "react";
import InputLimited from "../../../../ui/InputLimited";
import TextareaLimited from "../../../../ui/TextareaLimited";

interface IProps {
  isOpen: boolean;
  isOpenAdvance: boolean;
  onToggleAdvance: any;
  status: string;
  statusInternal: string;
  callers: string[];
  setCallers: any;
  reason: string;
  setReason: any;
  otherReason: string;
  setOtherReason: any;
  note: string;
  setNote: any;
  errors: any;
}

const OFFLINE_REASONS = [
  "Unavailable",
  "In a meeting",
  "With a client",
  "On the other line",
  "Out of the office",
  "Other",
];

const StatusMenuContextAdvanced = ({
  isOpen,
  isOpenAdvance,
  onToggleAdvance,
  status,
  statusInternal,
  callers,
  setCallers,
  reason,
  setReason,
  otherReason,
  setOtherReason,
  note,
  setNote,
  errors,
}: IProps) => {
  const onRemoveCaller = (iCaller: number) => () => {
    const newCallers = [...callers];
    newCallers.splice(iCaller, 1);
    setCallers(newCallers);
  };

  const onAddCaller = () => {
    setCallers([...callers, ""]);
  };

  // Keep advance settings open automatically if any field has value
  useEffect(() => {
    const otherReasonNotEmpty =
      "Offline" === status && "Other" === reason && otherReason;
    if (
      isOpen &&
      !isOpenAdvance &&
      (!!callers.join("").trim() || otherReasonNotEmpty || !!note)
    ) {
      onToggleAdvance();
    }
  }, [isOpen, callers, note]);

  return (
    <Flex flexDirection={"column"} width={"100%"}>
      <Flex justifyContent={"center"}>
        <Button variant={"link"} onClick={onToggleAdvance}>
          Advanced Settings
          <Box ml={2}>
            {isOpenAdvance ? <MdExpandLess /> : <MdExpandMore />}
          </Box>
        </Button>
      </Flex>
      <Box mx={"-2px"}>
        <Collapse in={isOpenAdvance}>
          <Flex flexDirection={"column"} mt={"27px"} px={"2px"} pb={"2px"}>
            <Flex fontSize={"14px"} lineHeight={"16px"} color={"#4F4F4F"}>
              {statusInternal === "Offline"
                ? "Connect only the following callers"
                : "Don’t connect the following callers"}
            </Flex>
            <Flex
              mt={"5px"}
              fontSize={"10px"}
              lineHeight={"12px"}
              color={"#B5B5B5"}
            >
              You can add up to 10 callers
            </Flex>
            {/* Callers */}
            <Flex flexDirection={"column"}>
              {callers.map((caller: string, iCaller: number) => (
                <Flex
                  key={`caller-${iCaller}`}
                  alignItems={"center"}
                  w={"100%"}
                  my={"15px"}
                >
                  <InputLimited
                    absoluteCounter
                    max={25}
                    value={caller}
                    onChange={(event: any) => {
                      const newCallers = [...callers];
                      newCallers[iCaller] = event.target.value;
                      setCallers(newCallers);
                    }}
                    height={"40px"}
                    borderRadius={"40px"}
                    fontSize={"14px"}
                    lineHeight={"19px"}
                    background={"#E4ECFC"}
                    border={"1px solid #CBD8F1"}
                    color={"#4F4F4F"}
                  />
                  <Flex ml={1}>
                    {callers.length > 1 && (
                      <Flex ml={1} onClick={onRemoveCaller(iCaller)}>
                        <Tooltip label="Remove Caller" placement={"top"}>
                          <Flex>
                            <MdCancel
                              style={{
                                width: 30,
                                height: 30,
                                cursor: "pointer",
                              }}
                            />
                          </Flex>
                        </Tooltip>
                      </Flex>
                    )}
                    {callers.length < 10 && iCaller === callers.length - 1 && (
                      <Flex ml={1} onClick={onAddCaller}>
                        <Tooltip label="Add Caller" placement={"top"}>
                          <Flex>
                            <MdAddCircle
                              style={{
                                width: 30,
                                height: 30,
                                fill: "#2852CC",
                                cursor: "pointer",
                              }}
                            />
                          </Flex>
                        </Tooltip>
                      </Flex>
                    )}
                  </Flex>
                </Flex>
              ))}
            </Flex>
            {/* Reason */}
            {statusInternal === "Offline" && (
              <>
                <Flex
                  mt={"14px"}
                  fontSize={"14px"}
                  lineHeight={"16px"}
                  color={"#4F4F4F"}
                >
                  How would you want us to explain your unavailability?
                </Flex>
                <Flex>
                  <Select
                    mt={"11px"}
                    value={reason}
                    onChange={(event: any) => {
                      setReason(event.target.value);
                    }}
                    height={"40px"}
                    background={"#E4ECFC"}
                    border={"1px solid #CBD8F1"}
                    borderRadius={"40px"}
                    fontWeight={400}
                    fontSize={"14px"}
                    lineHeight={"19px"}
                    color={"#666"}
                  >
                    {OFFLINE_REASONS.map((reason) => (
                      <option
                        key={`reason-${reason
                          .replaceAll(" ", "-")
                          .toLowerCase()}`}
                        value={reason}
                      >
                        {reason}
                      </option>
                    ))}
                  </Select>
                </Flex>
                {reason === "Other" && (
                  <Flex mt={"11px"}>
                    <FormControl>
                      <InputLimited
                        max={25}
                        value={otherReason}
                        onChange={(event: any) => {
                          setOtherReason(event.target.value);
                        }}
                        placeholder={"Enter other explanation"}
                        height={"40px"}
                        borderRadius={"40px"}
                        fontSize={"14px"}
                        lineHeight={"19px"}
                        background={"#E4ECFC"}
                        border={"1px solid #CBD8F1"}
                        color={"#4F4F4F"}
                      />
                      {errors["otherReason"] && (
                        <FormHelperText
                          color={"#E80B0B"}
                          fontSize={"12px"}
                          whiteSpace={"break-spaces"}
                        >
                          {errors["otherReason"]}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Flex>
                )}
              </>
            )}
            <Flex
              mt={"14px"}
              fontSize={"14px"}
              lineHeight={"16px"}
              color={"#4F4F4F"}
            >
              Additional Note
            </Flex>
            <TextareaLimited
              max={250}
              value={note}
              onChange={(event: any) => {
                setNote(event.target.value);
              }}
              mt={"11px"}
              placeholder="Enter Note"
              _placeholder={{ color: "#A5B5E3" }}
              borderRadius={"20px"}
              fontSize={"14px"}
              lineHeight={"19px"}
              background={"#E4ECFC"}
              border={"1px solid #CBD8F1"}
              color={"#4F4F4F"}
            />
          </Flex>
        </Collapse>
      </Box>
    </Flex>
  );
};

export default StatusMenuContextAdvanced;

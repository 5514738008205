import { FC } from "react";
import createContextSet from "src/utils/createContextSet";
import useSorting from "src/hooks/useSorting";
import { SortEnum } from "src/hooks/queries/activities/activitiesKeys";

type CallTagsContextReturnType = {
  [index: string]: any;
};

const [useCallTagsCtx, CallTagsCtxProvider] =
  createContextSet<CallTagsContextReturnType>();

const initialSortState = {
  sortBy: "name",
  sortType: SortEnum.ASC,
};

const CallTagsContextProvider: FC = ({ children }) => {
  const sortState = useSorting(initialSortState);

  return (
    <CallTagsCtxProvider value={{ sortState }}>{children}</CallTagsCtxProvider>
  );
};

export default CallTagsContextProvider;
export const useCallTagsContext = useCallTagsCtx;
